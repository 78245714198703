<!-- 添加银行卡 -->
<template>
  <div id="bind-box">
    <van-nav-bar :title="$t('bankform.title')" left-arrow @click-left="onClickLeft"></van-nav-bar>
    <!-- 内容 -->
    <div class="container">
      <div class="group">
        <van-form @submit="onSubmit">
          <van-field v-model="form.bank" name="bank" :label="$t('bankform.bankname')"
            :placeholder="$t('m_bankform.bankname')" />
          <van-field v-model="form.name" name="name" :label="$t('bankform.realname')"
            :placeholder="$t('m_bankform.realname')" />
          <van-field v-model="form.bank_card" name="bandnum" :label="$t('bankform.account')"
            :placeholder="$t('m_bankform.bandnum')" />
          <!-- <van-field
            v-model="form.bank_code"
            name="daima"
            :label="$t('bankform.bankdm')"
            :placeholder="$t('m_bankform.daima')"
          /> -->
          <van-field v-model="form.bank_address" name="address" :label="$t('bankform.bankadd')"
            :placeholder="$t('m_bankform.bandaddress')" />
          <div style="margin: 16px">
            <van-button class="addbtn" type="info" native-type="submit">{{
                $t("m_bankform.addbtn")
            }}</van-button>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>

<script>
import { bankAdd } from "@/api/user";
import { Toast } from "vant";
export default {
  name: "",
  data() {
    return {
      timer: null,
      username: "",
      password: "",
      form: {
        bank_card: "",
        bank: "",
        // bank_code: "",
        bank_address: "",
        name: "",
      },
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() { },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() { },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    loadingCom() {
      // 自定义加载图标
      const toast = Toast.loading({
        message: this.$t("common.loading"),
        forbidClick: true,
        loadingType: "spinner",
        duration: 60000,
      });
    },
    /* 点击提交 */
    onSubmit() {
      console.log("点击了提交");

      if (this.form.bank_card === "") {
        Toast.fail(this.$t("common.addmsg"));
        return;
      }
      if (this.form.bank === "") {
        Toast.fail(this.$t("common.addmsg"));
        return;
      }
      // if (this.form.bank_code === "") {
      //   Toast.fail(this.$t("common.addmsg"));
      //   return;
      // }
      if (this.form.bank_address === "") {
        Toast.fail(this.$t("common.addmsg"));
        return;
      }
      if (this.form.name === "") {
        Toast.fail(this.$t("common.addmsg"));
        return;
      }
      this.addBandFun();
    },
    /* 添加银行卡 */
    addBandFun() {
      this.loadingCom();
      bankAdd(this.form).then((res) => {
        console.log(res);
        Toast.clear();
        if (res.code + "" === "200") {
          Toast.success(this.$t("common.addSussess"));
          this.timer = setTimeout(() => {
            this.onClickLeft();
          }, 1000);
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "../../../assets/css/mobile.less";

.van-nav-bar {
  /* background-image: linear-gradient(0deg, #ceab77 10%, #eddbc1 100%) !important; */
  background-color: rgb(50, 59, 92) !important;
}

/deep/.van-nav-bar__title {
  /* color: #38373b !important; */
  color: #ffffff !important;
}

/deep/.van-nav-bar .van-icon {
  /* color: #38373b !important; */
  color: #ffffff !important;
}

/deep/.van-field__label {
  color: #ffffff;
}

#bind-box {
  height: 100%;
  background: #303e70 !important;

  .addbtn {
    width: 100%;
    background-image: linear-gradient(90deg, #2948ff, #396afc);
    /* border-color: #2979ff; */
    color: #ffffff;
    border: none;

    .van-button__text {
      color: #ffffff;
    }
  }
}
</style>